import axios from "axios";
import { decryptData } from "../../functions";

// Migrating to Mongo
export const proxy = 
   process.env.NODE_ENV === "production"
   ? "https://schools-x-server.onrender.com"
    : "http://localhost:4000";

export const mailerPath =
  process.env.NODE_ENV === "production"
    ? "https://schools-x-mailer.onrender.com"
    : "http://localhost:8000"; // ""

export const dbName = decryptData(localStorage.getItem("DBN")) || "ghs";
export const xmail = localStorage.getItem("xmail")
export const uploadData = async ({ data, path }) => {
  return axios.post(`${proxy + path}`, { ...data }, { headers: { dbName, xmail } });
};

export const getCount = async ({ path, getData }) => {
  const { data } = await axios.get(`${proxy + path}`, { headers: { dbName, xmail } });
  getData(data);
};

export const readDocuments = async ({ path, getData }) => {
  try {
    const { data } = await axios.get(`${proxy + path}`, {
      headers: { dbName, xmail },
    });
    getData(data);
  } catch (error) {
    console.log(error);
  }
};

export const readDocument = async ({ path, id, getData }) => {
  try {
    const { data } = await axios.get(`${proxy + path}/${id}`, {
      headers: { dbName, xmail },
    });

    getData(data);
  } catch (error) {
    console.log(error);
  }
};

export const updateData = async ({ path, id, data }) => {
  return axios.patch(`${proxy + path}/${id}`, data, { headers: { dbName, xmail } });
};

export const deleteData = async ({ path, id }) => {
  try {
    await axios.delete(`${proxy + path}/${id}`);
  } catch (error) {
    console.log(error);
  }
};
