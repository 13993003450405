
import CryptoJS from "crypto-js";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
const encryptKey = 'x-constants'

export const encryptData = (text) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    encryptKey
  ).toString();

  return data;
};

export const decryptData = (text) => {
  try {
    const bytes = CryptoJS.AES.decrypt(text, encryptKey);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  } catch (error) {
    return null;
  }
};


export const formatMail = (id) => {
  const addr = id.replaceAll("-",".")

  return addr+"@lschoolx.com".toLowerCase()
}

export const schoolID = decryptData(localStorage.getItem("schoolID"))


export const sortResults = (arr) => {
  return arr
    .sort((a, b) => {
      return a - b;
    })
    .reverse();
};

export const getImage = async (url, exam) => {
  await fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      reader.onload = () => {
        exam.photoURI = reader.result;
        //   this.setState({ data_image: reader.result });
      };
      reader.onerror = (err) => {
        console.log(err);
      };
      reader.readAsDataURL(blob);
    })
    .catch((err) => {
      console.log("Error: ", err);
    });
};

export const useQuery = () =>  {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const findGrade = (mark) => {
  if (mark < 41) {
    return 9;
  }
  if (mark >= 41 && mark < 50) {
    return 8;
  }
  if (mark >= 50 && mark < 56) {
    return 7;
  }
  if (mark >= 56 && mark < 60) {
    return 6;
  }
  if (mark >= 60 && mark < 70) {
    return 5;
  }

  if (mark >= 70 && mark <= 79) {
    return 4;
  }

  if (mark >= 80 && mark < 85) {
    return 3;
  }

  if (mark >= 85 && mark < 91) {
    return 2;
  }

  if (mark >= 91) {
    return 1;
  }
};

export const findRemarks = (grade) => {
  switch (grade) {
    case 1:
      return "Excellent";
    case 2:
      return "Very Good";
    case 3:
      return "Good";
    case 4:
      return "High Average";
    case 5:
      return "Average";
    case 6:
      return "Low Average";

    case 7:
      return "Low";
    case 8:
      return "Lower";

    default:
      return "Lowest";
  }
};